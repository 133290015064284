.card {
  width: 100%;
  height: 550px;
  max-width: 450px;
  background-color: #3a3a3a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.cardImg {
  max-width: 450px;
  max-height: 250px;
}

.cardImg > img {
  width: 100%;
  height: 250px;
  background-color: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cardTitle {
  margin-top: 1rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  font-size: 1.4rem;
}

.cardBody {
  padding: 0.7rem;
  font-size: 1.1rem;
  font-family: "Poppins";
  max-height: 150px;
  overflow: auto;
}

.extras {
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-evenly;
}

.websiteIcon {
  height: auto;
  width: 30px;
  filter: invert(100%);
}

@media screen and (max-width: 500px) {
  .card {
    padding-bottom: 0;
  }
  .cardImg > img {
    height: 200px;
  }
}

@media screen and (max-width: 450px) {
  .card {
    height: 100%;
  }
  .cardImg > img {
    height: 175px;
  }
  .extras {
    margin-bottom: 1rem;
  }
}
