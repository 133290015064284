.worldUI {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.worldUINavContainer {
  max-height: 70px;
  width: 100%;
  background-color: var(--world-ui-color-bg);
  display: flex;
  justify-content: center;
}

.worldUINav {
  width: var(--margin-provider);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.theChosenOne {
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  white-space: nowrap;
}

.navButtons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.navButton {
  width: 100%;
  max-width: 120px;
  font-size: 1rem;
  margin-left: 1.5rem;
  padding: 7px 12px;
  background-color: var(--world-ui-color-secondary);
  border-radius: 10px;
  transition: var(--transition-slow);
}

.navButton:hover {
  background-color: #00000088;
}

.navButton:active {
  transition: all 0.1s ease-in-out;
  background-color: #000000cc;
}

.worldUIBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructions {
  margin-top: 2rem;
  padding: 1rem;
  width: 80%;
  text-align: left;
  font-family: "Poppins";
  background-color: var(--world-ui-color-bg);
  border-radius: 20px;
}

.instructionListItem {
  display: flex;
  align-items: center;
}

.instructionListItem:last-child {
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: center;
  font-size: 1.5rem;
}

.instructionListItem {
  font-size: 1.2rem;
}

.instructionListItem > p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.keyImg {
  width: 60px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.keyImgSmall {
  width: 40px;
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.startMessage {
  margin-top: auto;
  border-radius: 20px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: var(--world-ui-color-secondary);
  margin-bottom: auto;
}

.upArrow {
  filter: invert(100%);
}
