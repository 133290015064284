.aboutBgWrapper {
  background-image: url("../../../assets/about-bg.webp");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  display: flex;
}

.About {
  color: #ffffff;
  background-color: #000000aa;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.aboutNavContainer {
  height: 100%;
  max-height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.aboutNav {
  height: 100%;
  width: var(--margin-provider);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navTitle {
  white-space: nowrap;
  font-size: 1.7rem;
  font-weight: 500;
}

.aboutNavButtons {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.aboutNavButton {
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-right: 1rem;
  background-color: transparent;
  border: 2px solid #177d43;
  color: #177d43;
  border-radius: 10px;
  padding: 3px 12px;
  transition: var(--transition-slow);
}

.aboutNavButton:hover {
  border-color: #1a934f;
  color: #1a934f;
}

.aboutNavButton:active {
  background-color: #177d43;
  color: #ffffff;
  transition: var(--transition-fast);
}

.aboutBody {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.vesselOfGod {
  height: auto;
  width: 100%;
  max-width: 220px;
  border-radius: 50%;
}

.god {
  height: auto;
  width: 100%;
  max-width: 220px;
  border-radius: 50%;
}

.doGodsHaveToGiveIntros {
  font-size: 1.2rem;
  letter-spacing: 1px;
  max-width: min(850px, var(--margin-provider));
  line-height: 1.7rem;
  max-height: 55vh;
  overflow: auto;
  margin-bottom: 2rem;
}

.links {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-around;
}

.link {
  font-size: 1.2rem;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  font-family: "Poppins";
  background-color: #2b2b2b;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
}

.socialIcon {
  height: auto;
  width: 2rem;
  filter: invert(1);
  transition: var(--transition-fast);
}

.socialIcon:hover {
  transition: var(--transition-slow);
  filter: invert(0.7);
}

@supports (backdrop-filter: blur(2px)) {
  .About {
    backdrop-filter: blur(2px);
    background-color: #000000cc;
  }
}

@media screen and (max-width: 500px) {
  .About {
    height: 100%;
  }
  .vesselOfGod {
    margin-top: 3rem;
  }
  .doGodsHaveToGiveIntros {
    margin-top: 2rem;
  }
  .links {
    margin-top: 2rem;
  }
  .navTitle {
    font-size: 1.5rem;
  }
  .socials {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .aboutNavContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navTitle {
    font-size: 1.2rem;
  }
  .aboutNavButton {
    font-size: 0.8rem;
    padding: 2px 4px;
    margin-right: 0;
  }
  .doGodsHaveToGiveIntros {
    font-size: 1.05rem;
  }
}
