.playerRadar {
  position: absolute;
  bottom: 0;
  height: 25vh;
  width: 25vh;
  min-width: 10rem;
  min-height: 10rem;
  background-color: var(--world-ui-color-bg);
}

.playerRadar canvas {
  width: 100%;
  height: 100%;
}

.playerStats {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25vh;
  width: 40vh;
  min-height: 10rem;
  min-width: 15rem;
  background-color: var(--world-ui-color-bg);
}

.playerStatsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin: 1rem;
  margin-top: 0;
}

.playerStatsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.playerStatsLabel {
  font-size: 1rem;
}

.playerStatsValue {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .playerRadar {
    height: 15vh;
    width: 15vh;
    min-height: 8rem;
    min-width: 8rem;
    bottom: calc(50% - max(15vh / 2, 8rem / 2));
  }

  .playerStats {
    height: 15vh;
    width: 15vh;
    min-height: 8rem;
    min-width: 10rem;
    left: 0;
  }
}
