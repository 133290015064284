@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --margin-provider: calc(100vw - 6rem);
  --world-ui-color-bg: #00000033;
  --world-ui-color-secondary: #00000066;
  --transition-slow: all 0.3s ease-in-out;
  --transition-fast: all 0.1s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #eaeaeaea;
}

a {
  text-decoration: none;
  color: #ffffff;
}

button {
  border: none;
  background: none;
  color: #eaeaeaea;
  cursor: pointer;
}

#nav-title > .wrap {
  border-right: 0.08em solid #666;
}

@media screen and (max-width: 500px) {
  :root {
    --margin-provider: calc(100vw - 3rem);
  }
}
